<template>
  <v-card outlined class="rounded-lg" elevation="0" width="100%">
    <v-sheet :color="`grey lighten-4`">
      <v-skeleton-loader :type="type"></v-skeleton-loader>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
    name: "skeleton",
    props: {
        type: {
            type: String,
            default: 'card-avatar'
        }
    }
};
</script>

<style lang="scss">
</style>