<template>
    <div class="purchases-page">
        <layout :header="$t('purchasesMenu')" @goback="$router.push('/')">
            <template v-slot:content>
                <div class="purchases-body">
                    <v-row justify="center" align-center="start">
                        <v-col cols="12" xs="12" sm="10">
                            <v-tabs v-model="tab" background-color="white" color="#9D662C" grow class="tab-purchases"
                                height="55px" width="100%">
                                <v-tab v-for="item in items" :key="item" :ripple="false">{{ item }}</v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="12">
                            <v-tabs-items v-model="tab" style="background:transparent;">
                                <v-tab-item>
                                    <v-row justify="start" dense v-if="!loadingState && coupon_codes.length > 0">
                                        <v-col cols="6" xs="6" sm="4" md="3" lg="2"
                                            v-for="(item_coupon,index) in coupon_codes" :key="index+'coupon'">
                                            <v-card
                                                @click="$router.push('/Purchases/Detail/Coupon/' + item_coupon.objectId)"
                                                class="rounded-lg">
                                                <v-img class="white--text align-end" aspect-ratio="1.5" cover
                                                    :src="item_coupon.coupon_list_id.image_url || '/image/logo.png'">
                                                    <template v-slot:placeholder>
                                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                                            <v-progress-circular indeterminate color="#463729">
                                                            </v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                                <v-card-text class="text--primary pa-2">
                                                    <div class="title-cou">
                                                        <!-- {{$i18n.locale =='th' ? item_coupon.coupon_id.name || '' : item_coupon.coupon_id.name_en || ''}} -->
                                                        {{item_coupon.coupon_list_id.name}}
                                                    </div>
                                                    <div class="expired-cou"
                                                        v-if="item_coupon.expire_at != undefined">
                                                        {{$t('expire')}} <br> {{DateTime(item_coupon.expire_at.iso) || ''}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" align="center"
                                        v-else-if="!loadingState && coupon_codes.length == 0">
                                        <v-col cols="12" xs="12" sm="8" md="6">
                                            <v-card elevation="1" class="rounded-lg pa-5 text-center">
                                                {{$t('nodata')}}
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start" dense v-else>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="j in 5"
                                            :key="j+'coupon_load'">
                                            <skeleton></skeleton>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row justify="start" dense v-if="!loadingState && reward_codes.length > 0">
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2"
                                            v-for="(item_reward,index) in reward_codes" :key="index+'reward'">
                                            <v-card
                                                @click="$router.push('/Purchases/Detail/Reward/' + item_reward.objectId)"
                                                class="rounded-lg" outlined>
                                                <v-img class="white--text align-end"
                                                    :src="item_reward.coupon_list_id.image_url || '/image/logo.png'"
                                                    height="200px" cover>
                                                    <template v-slot:placeholder>
                                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                                            <v-progress-circular indeterminate color="#463729">
                                                            </v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                    
                                                </v-img>
                                                <v-card-title class="pb-1 pt-1">
                                                        {{$i18n.locale =='th' ? item_reward.coupon_id.name || '' : item_reward.coupon_id.name_en || ''}}
                                                    </v-card-title>
                                                    <v-card-subtitle class="text-title pt-2 pb-1">
                                                        {{$t('expire')}} {{DateTime(item_reward.expire_at.iso) || ''}}
                                                    </v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" align="center"
                                        v-else-if="!loadingState && reward_codes.length == 0">
                                        <v-col cols="12" xs="12" sm="8" md="6">
                                            <v-card elevation="1" class="rounded-lg pa-5 text-center">
                                                {{$t('nodata')}}
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start" dense v-else>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="j in 5"
                                            :key="j+'award_load'">
                                            <skeleton></skeleton>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-row justify="start" dense v-if="!loadingState  && used_codes.length > 0">
                                        <v-col cols="6" xs="6" sm="4" md="3" lg="2"
                                            v-for="(item_used,index) in used_codes" :key="index+'used'">
                                            <v-card
                                                @click="$router.push(item_used.coupon_list_id.type == 'sale' ? '/Purchases/Used/Coupon/' + item_used.objectId: '/Purchases/Used/Reward/' + item_used.objectId)"
                                                class="rounded-lg">
                                                <v-img class="white--text align-end" aspect-ratio="1.5" cover
                                                    :src="item_used.coupon_list_id.image_url || '/image/logo.png'">
                                                    <template v-slot:placeholder>
                                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                                            <v-progress-circular indeterminate color="#463729">
                                                            </v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                                <v-card-text class="text--primary pa-2">
                                                    <div class="title-cou">
                                                        {{$i18n.locale =='th' ? item_used.coupon_id.name || '' : item_used.coupon_id.name_en || ''}}
                                                    </div>
                                                   <div class="expired-cou"
                                                        v-if="item_used.expire_at != undefined">
                                                        {{$t('expire')}} <br> {{DateTime(item_used.expire_at.iso) || ''}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center" align="center"
                                        v-else-if="!loadingState && used_codes.length == 0">
                                        <v-col cols="12" xs="12" sm="8" md="6">
                                            <v-card elevation="1" class="rounded-lg pa-5 text-center">
                                                {{$t('nodata')}}
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start" dense v-else>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="j in 5"
                                            :key="j+'used_load'">
                                            <skeleton></skeleton>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </layout>
        <loading v-show="loadingState"></loading>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1";
    import Loading from "../../components/loading";
    import Skeleton from "../../components/skeleton";
    export default {
        name: "Purchases",
        components: {
            layout: Layout,
            loading: Loading,
            skeleton: Skeleton
        },
        data: () => ({
            loadingState: true,
            tab: 0,
            items: ["Coupons", "Rewards", "Used"],
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        }),
        computed: {
            coupon_codes() {
                return this.$store.getters.coupon_codes;
            },
            reward_codes() {
                return this.$store.getters.reward_codes;
            },
            used_codes() {
                return this.$store.getters.used_codes;
            }
        },
        async beforeCreate() {
            await this.$store.dispatch('get_coupon_codes');
            await this.$store.dispatch("getBalance");
            this.loadingState = false;
        }
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .purchases-page {
        height: 100%;

        .purchases-body {
            padding: 10px;
            width: 100%;
            background: transparent !important;

            .title-cou {
                font-size: 14px;
                font-weight: 800;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.6;
                max-height: 30px;
                white-space: nowrap;
            }

            .text-title {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }

            .expired-cou {
                font-size: 12px;
                line-height: 1.2;
                color: #969696;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }
        }
    }
</style>